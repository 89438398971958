import { useState } from 'react'
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { emitError } from 'common/utils/event-emitter'
import { ClientError } from 'graphql-request'

interface IApiProviderContextProps {
  children: React.ReactNode
}

const getErrorMessage = (error: Error) => {
  if (error instanceof ClientError) {
    if (error?.response?.errors?.at(0)?.message) {
      const message = error?.response?.errors?.at(0)?.message ?? ''
      return message
    }

    if (error?.response?.['error']) {
      const message = error?.response?.['error'] ?? ''
      return message
    }
  }

  return ''
}

const ApiProviderContext = (props: IApiProviderContextProps) => {
  const { children } = props
  const [queryClient] = useState(
    new QueryClient({
      queryCache: new QueryCache({
        onError: (error, query) => {
          const errorMessage = query?.meta?.errorMessage
          let clientErrorMessage = getErrorMessage(error)
          if (errorMessage && typeof errorMessage === 'string') {
            emitError(`${errorMessage} ${clientErrorMessage}`)
          }
        },
      }),
    })
  )

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  )
}

export default ApiProviderContext
